<template>
  <div>
    <PageHeader
      :back="false"
      :icon="$t('COMPANY.ICON')"
      :title="$t('COMPANY.TITLE')"
    >
      <template slot="nav">
        <small class="numeric"><strong>#{{this.company_id[0]}}</strong></small>
      </template>
      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="#CFD8DC"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="showEdit = true"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.EDIT') }}
      </v-tooltip>
    </PageHeader>

    <Side
      component="forms/company/compact/FormCompanyCompact"
      :value="showEdit"
      :ids="company_id"
      @close="closeEdit"
      @update="updatePage"
    />

    <Loading :value="loading" />

    <v-row class="ma-5" align="center">
      <v-img
        max-width="250"
        min-width="250"
        class="company_avatar"
        :src="company.images || '/img/empty-image.png'"
      />
      <v-col>
        <div class="ml-5 mt-3">
          <h2>{{ company.title }}</h2>
          <p>{{ company.country }} - {{ $t('COMMON.CREATED') }} {{ $moment(company.created_at).fromNow() }}
            - {{ $t('INPUT.UPDATED') }} {{ $moment(company.updated_at).fromNow() }}</p>
          <p>{{ company.description }}</p>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-2 my-0">
      <v-col md="4" sm="6">
        <v-list outlined class="px-5" rounded>
          <v-list-item-title><h3>{{ $t('COMPANY.CONTACT') }}</h3><br></v-list-item-title>
          <v-list-item v-if="company.contact_name"><span>{{ company.contact_name }}</span></v-list-item>
          <v-list-item v-if="company.contact_email"><span>{{ company.contact_email }}</span></v-list-item>
          <v-list-item v-if="company.contact_address"><span>{{ company.contact_address }}</span></v-list-item>
        </v-list>
      </v-col>

      <v-col md="4" sm="6">
        <v-list outlined class="px-5" rounded>
          <v-list-item-title><h3>{{ $t('OFFICE.TITLES') }}</h3><br></v-list-item-title>
          <v-list-item
            v-for="(office, index) in company.offices"
            :key="`office${index + 1}`"
          >
            <span>
              {{ office.title }}
              <v-icon dense v-if="office.id === office_id">mdi-check</v-icon> <br>
            </span>
          </v-list-item >
        </v-list>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import PageHeader from "@/components/PageHeader"
import Loading from "@/components/Loading"
import Side from '@/components/Side'

export default {
  name: 'PageSettingsXadminCompany',
  components: {
    PageHeader,
    Loading,
    Side,
  },
  data: () => ({
    showEdit: false,
    valid: false,
    loading: false,
    company_id: [],
    office_id: null,
    company: {
      title: null,
      description: null,
      country: null,
      created_at: null,
      updated_at: null,
      contact_name: null,
      contact_email: null,
      contact_address: null,
      images: null,
      offices: [],
    }
  }),
  async created() {
    try {
      this.loading = true
      this.loadCompany()
    } finally {
      this.loading = false
    }
  },
  methods: {
    async loadCompany () {
      const userSession = await this.$services.api_user.user_session.check()
      this.company_id.push(userSession.data.user.company.id)
      this.office_id = userSession.data.user.office.id

      const response = await this.$services.api_user.company.search({where: { id: this.company_id[0] }})
      this.company.title = response.data[0].title
      this.company.description = response.data[0].description
      this.company.country = response.data[0].country.title
      this.company.created_at = response.data[0].created_at
      this.company.updated_at = response.data[0].updated_at
      this.company.contact_name = response.data[0].contact_name
      this.company.contact_email = response.data[0].contact_email
      this.company.contact_address = response.data[0].contact_address
      this.company.images = response.data[0].images
      this.company.offices = response.data[0].offices
    },

    closeEdit () {
      this.showEdit = false
    },

    updatePage () {
      this.$store.dispatch('app/init')
    },
  }
}
</script>

<style scoped>
.company_avatar {
  border-radius: 3%;
  border: 1px solid black;
}
</style>